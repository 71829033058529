const v8pagination = {
  forms: document.querySelectorAll('.v8Form'),

  init() {
    const parentObj = this;
    console.log(parentObj);
    for (const form of this.forms) {
      parentObj.checkButtons(form);
      form.addEventListener('click', this.handleListeners(parentObj, form));
      form.addEventListener(
        'keypress',
        this.accessibleKeyboardInteractivity(form)
      );
    }
  },

  handleListeners: function (parentObj, form) {
    return function (event) {
      if (
        event.target.closest('.v8Form__button') ||
        event.target.closest('.v8Form__button-content')
      ) {
        parentObj.processButtonClicks(form, event, parentObj);
      }

      if (event.target.matches('.v8Form__single-step')) {
        parentObj.processMenuClicks(form, event, parentObj);
      }
    };
  },

  processButtonClicks: function (form, event, parentObj) {
    const fields = form.querySelector('.v8Fields');
    const activePage = fields.querySelector('.activePage');
    const pageNumber = parseInt(activePage.dataset.pagenumber);

    // Next Button
    if (event.target.matches('.v8Form__next')) {
      const nextPage = fields.querySelector(
        `[data-pagenumber="${pageNumber + 1}"]`
      );

      // We need to focus on the next step for accessibility
      const targetStep = form.querySelector(
        `.v8Form__single-step[data-pagenumber="${pageNumber + 1}"]`
      );
      targetStep.focus();

      activePage.classList.remove('activePage');
      nextPage.classList.add('activePage');

      parentObj.markCompletedPages(form, pageNumber + 1);
    }

    // Previous Button
    if (event.target.matches('.v8Form__previous')) {
      const prevPage = fields.querySelector(
        `[data-pagenumber="${pageNumber - 1}"]`
      );

      activePage.classList.remove('activePage');
      prevPage.classList.add('activePage');
      parentObj.markCompletedPages(form, pageNumber - 1);
    }

    // Submit Button {
    if (event.target.closest('.v8Form__submit')) {
      event.preventDefault();
      parentObj.validateForm(form, parentObj);
    }

    parentObj.checkButtons(form);
  },

  checkButtons(form) {
    const steps = form.querySelectorAll('.v8Form__single-step');
    const activePage = form.querySelector('.activePage');
    const previousButton = form.querySelector('.v8Form__previous');
    const nextButton = form.querySelector('.v8Form__next');
    const submitButton = form.querySelector('.v8Form__submit');

    // Page 1 - Show only Continue
    if (parseInt(activePage.dataset.pagenumber) === 1) {
      previousButton.style.display = 'none';
      submitButton.style.display = 'none';
      nextButton.style.display = 'inline-block';
    }

    // Middle Pages - Show Return and Continue
    if (
      parseInt(activePage.dataset.pagenumber) > 1 &&
      parseInt(activePage.dataset.pagenumber) < steps.length
    ) {
      previousButton.style.display = 'inline-block';
      nextButton.style.display = 'inline-block';
      submitButton.style.display = 'none';
    }

    // End Page - Show Submit
    if (parseInt(activePage.dataset.pagenumber) === steps.length) {
      previousButton.style.display = 'inline-block';
      nextButton.style.display = 'none';
      submitButton.style.display = 'inline-block';
    }
  },

  processMenuClicks(form, event, parentObj) {
    const steps = form.querySelectorAll('.v8Form__single-step');
    const fields = form.querySelector('.v8Fields');
    const activePage = fields.querySelector('.activePage');
    const pageNumber = parseInt(event.target.dataset.pagenumber);
    const targetPage = fields.querySelector(
      `[data-pagenumber="${pageNumber}"]`
    );
    for (const step of steps) {
      step.classList.remove('activeMenuItem');
    }
    event.target.classList.add('activeMenuItem');

    activePage.classList.remove('activePage');
    targetPage.classList.add('activePage');

    parentObj.checkButtons(form);
    parentObj.markCompletedPages(form);
  },

  markCompletedPages: function (form, pageNumber = null) {
    let stepCounter = 0;
    const steps = form.querySelector('.v8Form__steps');

    if (pageNumber) {
      const targetStep = steps.querySelector(
        `[data-pagenumber="${pageNumber}"]`
      );

      const activeStep = steps.querySelector('.activeMenuItem');
      targetStep.classList.add('activeMenuItem');
      activeStep.classList.remove('activeMenuItem');
    }

    for (const step of steps.children) {
      step.classList.remove('completedMenuItem');
    }

    while (!steps.children[stepCounter].classList.contains('activeMenuItem')) {
      steps.children[stepCounter].classList.add('completedMenuItem');
      stepCounter++;
    }
  },

  validateForm: function (form, parentObj) {
    const formEl = form.querySelector('.v8Form__form');
    formEl.classList.add('v8-submitting');

    const spinner = document.createElement('div');
    spinner.classList.add('v8-spinner');
    spinner.innerHTML = `<div class="v8-spinner-container"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
    form.querySelector('.v8-submitting').parentElement.appendChild(spinner);

    const config = {
      // class of the parent element where the error/success class is added
      classTo: 'v8Fields__field',
      errorClass: 'has-danger',
      successClass: 'has-success',
      // class of the parent element where error text element is appended
      errorTextParent: 'v8Fields__field',
      // type of element to create for the error text
      errorTextTag: 'div',
      // class of the error text element
      errorTextClass: 'text-help',
    };

    const pristine = new Pristine(formEl, config);

    // Pristine seems to not validate required on textarea by default.  Adding it Manually

    const textAreas = form.querySelectorAll('textarea');

    for (const textarea of textAreas) {
      pristine.addValidator(
        textarea,
        function (value) {
          if (this.required && value === ' ') {
            return false;
          }
          return true;
        },
        'This Field is Required',
        2,
        true
      );
    }

    let valid = pristine.validate();

    // Replace This Alert
    if (!valid) {
      alert(
        'The form is missing items. Please go back and fill in all required items'
      );

      for (const field of pristine.fields) {
        // The Flatpickr causes the validation to bug out somehow.
        // This fix will manually add an error to the input on submit if a date is not selected
        if (
          field.input.className === 'flatpickr-input' &&
          field.errors.length > 0
        ) {
          pristine.addError(field.input, 'Please select a date');
        }
      }

      for (const field of pristine.fields) {
        if (field.errors.length > 0) {
          const formFields = form.querySelector('.v8Fields');
          const pages = formFields.querySelectorAll('.v8Fields__page');
          const errorPage = field.input.closest('.v8Fields__page');
          const submitPage = pages[pages.length - 1];

          if (errorPage.dataset.pagenumber != pages.length) {
            submitPage.classList.remove('activePage');
            errorPage.classList.add('activePage');
            const pageNumber = parseInt(errorPage.dataset.pagenumber);

            parentObj.checkButtons(form);
            parentObj.markCompletedPages(form, pageNumber);
          }

          break;
        }
      }

      // Remove Submitting Class and Spinner
      formEl.classList.remove('v8-submitting');
      spinner.remove();
    } else {
      formEl.submit();
    }
  },

  accessibleKeyboardInteractivity: function (form) {
    return function (event) {
      if (event.target.classList.contains('v8Form__button')) {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.target.click();
        }
      }

      if (event.target.classList.contains('v8Form__single-step')) {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.target.click();
        }
      }

      if (event.target.classList.contains('v8Fields__radio-single')) {
        if (event.key === 'Enter') {
          event.target.click();
          event.firstChild.target.click();
        }
      }

      if (event.target.classList.contains('flatpickr-monthDropdown-months')) {
        if (event.key === 'Enter') {
          event.target.click();
        }
      }

      if (event.key === 'Enter') {
        const activePage = form.querySelector('.activePage');
        const formInputTypes = [
          'input',
          'textarea',
          'select',
          '.v8Fields__radio-single',
          '.flatpickr-day',
          '.flatpickr-monthDropdown-months',
          '.flatpickr-monthDropdown-month',
        ];

        formInputTypes.forEach((type) => {
          const pageInputs = activePage.querySelectorAll(type);
          const allInputs = form.querySelectorAll(type);

          for (const input of allInputs) {
            input.tabIndex = -1;
          }

          for (const input of pageInputs) {
            console.log(input);
            input.tabIndex = 0;
          }
        });
      }
    };
  },
}.init();
